import React, { useState } from 'react';
import Web3 from 'web3';
import './index.css';  // Import the custom CSS file

const App = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [noVestingMessage, setNoVestingMessage] = useState(false);

  const INFURA_PROJECT_ID = 'b9c552155c2941b8b77df7f8310f0476';
  const CONTRACT_ADDRESS = '0xC16C22944d4664Fe9d199B760fdFd55De8298368';

  const vestingContractABI = [
    {
      "inputs": [
        { "internalType": "address", "name": "_marketing_address", "type": "address" },
        { "internalType": "address", "name": "_referral_reward_wallet", "type": "address" }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {"inputs":[{"internalType":"address","name":"owner","type":"address"}],"name":"OwnableInvalidOwner","type":"error"},
    {"inputs":[{"internalType":"address","name":"account","type":"address"}],"name":"OwnableUnauthorizedAccount","type":"error"},
    {"inputs":[],"name":"Vesting__CantReferYourself","type":"error"},
    {"inputs":[],"name":"Vesting__DontHaveFunds","type":"error"},
    {"inputs":[],"name":"Vesting__LockingPeriodIsNotOver","type":"error"},
    {"inputs":[],"name":"Vesting__NoAmountRemaining","type":"error"},
    {"inputs":[],"name":"Vesting__SameAddresses","type":"error"},
    {"inputs":[],"name":"Vesting__UserNotExists","type":"error"},
    {"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"previousOwner","type":"address"},{"indexed":true,"internalType":"address","name":"newOwner","type":"address"}],"name":"OwnershipTransferred","type":"event"},
    {"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"by","type":"address"}],"name":"Paused","type":"event"},
    {"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"by","type":"address"}],"name":"UnPaused","type":"event"},
    {"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"by","type":"address"},{"indexed":false,"internalType":"uint256","name":"amount","type":"uint256"},{"indexed":false,"internalType":"uint256","name":"timeStamp","type":"uint256"}],"name":"Withdrawed","type":"event"},
    {"inputs":[{"internalType":"address","name":"_toAdd","type":"address"}],"name":"addAuthorized","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"","type":"address"}],"name":"authorized","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"_userAddress","type":"address"},{"internalType":"uint256","name":"_amount","type":"uint256"},{"internalType":"uint256","name":"_amountToBeGiven","type":"uint256"},{"internalType":"uint256","name":"_cliffTime","type":"uint256"},{"internalType":"address","name":"_referral","type":"address"}],"name":"deposit","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"_qorra","type":"address"},{"internalType":"address","name":"_ico","type":"address"}],"name":"initialize","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"owner","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},
    {"inputs":[],"name":"pauseContract","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"_toRemove","type":"address"}],"name":"removeAuthorized","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"renounceOwnership","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"_oldAddress","type":"address"},{"internalType":"address","name":"_newAddress"}],"name":"switchAccount","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"newOwner","type":"address"}],"name":"transferOwnership","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"user","type":"address"},{"internalType":"uint256","name":"index","type":"uint256"}],"name":"txHistory","outputs":[{"internalType":"uint256","name":"amountRemaining","type":"uint256"},{"internalType":"uint256","name":"nextClaimTime","type":"uint256"},{"internalType":"uint256","name":"amountToBeGiven","type":"uint256"},{"internalType":"uint256","name":"lastClaimTime","type":"uint256"}],"stateMutability":"view","type":"function"},
    {"inputs":[],"name":"unPauseContract","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"user","type":"address"}],"name":"userDetails","outputs":[{"internalType":"uint256","name":"noOfTx","type":"uint256"},{"internalType":"address","name":"referrer","type":"address"}],"stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"_index","type":"uint256"}],"name":"withdraw","outputs":[],"stateMutability":"nonpayable","type":"function"}
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setTransactions([]);
    setNoVestingMessage(false);

    if (!walletAddress) {
      setError('Please enter a wallet address');
      return;
    }

    const web3 = new Web3(`https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`);
    const vestingContract = new web3.eth.Contract(vestingContractABI, CONTRACT_ADDRESS);

    try {
      const userDetails = await vestingContract.methods.userDetails(walletAddress).call();
      const transactions = [];

      if (Number(userDetails.noOfTx) === 0) {
        setNoVestingMessage(true);
        return;
      }

      for (let i = 0; i < userDetails.noOfTx; i++) {
        try {
          const transaction = await vestingContract.methods.txHistory(walletAddress, i).call();
          transactions.push({
            amountRemaining: web3.utils.fromWei(transaction.amountRemaining.toString(), 'ether'),
            nextClaimTime: new Date(Number(transaction.nextClaimTime) * 1000).toLocaleString('en-US', { timeZone: 'UTC' }),
            amountToBeGiven: web3.utils.fromWei(transaction.amountToBeGiven.toString(), 'ether'),
            lastClaimTime: new Date(Number(transaction.lastClaimTime) * 1000).toLocaleString('en-US', { timeZone: 'UTC' }),
          });
        } catch (txError) {
          setError(`Error fetching transaction ${i} details: ${txError.message}`);
        }
      }

      setTransactions(transactions);
    } catch (error) {
      setError(`Error fetching vesting data: ${error.message}`);
    }
  };

  return (
    <div className="container mx-auto p-4 min-h-screen bg-themeColor text-white font">
      <h1 className="heading mb-4">Qorra Vesting Checker</h1>
      <form onSubmit={handleSubmit} className="mb-4">
        <label className="block text-sm font-medium">
          Wallet Address:
          <input
            type="text"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            placeholder="Enter your wallet address"
            className="mt-1 block w-full p-2 border border-gray-300 text-black"
          />
        </label>
        <button type="submit" className="mt-4 w-full px-4 py-2 bg-themeColor2 hover:bg-themeColor2 text-black1 font-bold">
          CHECK VESTING
        </button>
      </form>
      {error && <p className="text-red-500">{error}</p>}
      {noVestingMessage && <p className="text-themeColor2">Sorry, there are no vested tokens for this wallet address.</p>}
      {transactions.length > 0 && (
        <div className="mt-4">
          {transactions.map((tx, index) => (
            <div key={index} className="mb-4 p-4 border border-gray-200 bg-black2">
              <p className="text-themeColor2">Transaction {index} Details:</p>
              <p className="text-themeColor2">Amount Remaining: {tx.amountRemaining}</p>
              <p className="text-themeColor2">Next Claim Time: {tx.nextClaimTime}</p>
              <p className="text-themeColor2">Amount To Be Given: {tx.amountToBeGiven}</p>
              <p className="text-themeColor2">Last Claim Time: {tx.lastClaimTime}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default App;
